import firebase from "firebase/app";
import {restaurantId} from "@/restaurantConfig";
import Vue from 'vue'

export const restaurantStore = {
    namespaced: true,
    state: {
        id: restaurantId,
        hasCheckedInfo: false,
        zipCodes: [],
        paymentOptions: [],
        acceptingOrders: undefined,
        outOfDate: false,
        discountCodes:[],
        hasCheckedDiscountCodes: false,
        openingHours: [],
        restaurantAddress: {},
    },
    getters: {
        openingHours(state){
          return state.openingHours
        },
        isCurrentTimeInOpeningHours(state){
            let currentTime = new Date()
            let currentDay = currentTime.getDay()
            let currentHour = String(currentTime.getHours()).length < 2 ? '0' + String(currentTime.getHours()) : String(currentTime.getHours())
            let currentMinute = String(currentTime.getMinutes()).length < 2 ? '0' + String(currentTime.getMinutes()) : String(currentTime.getMinutes())
            let correspondingOpeningHours = state.openingHours.filter(x => {
                if(!(x.day == currentDay)){
                    return false
                }
                let openingArr = x.openingTime.split(':')
                let closingArr = x.closingTime.split(':')
                if (((openingArr[0] < currentHour) || ( openingArr[0] == currentHour && openingArr[1] <= currentMinute)) && ( (closingArr[0] > currentHour) || (closingArr[0] == currentHour && closingArr[1] > currentMinute) )) {
                    return true
                } else {
                    return false
                }
            })
            return correspondingOpeningHours.length > 0
        },
        discountCodes(state){
          return state.discountCodes
        },
        activeDiscountCodes(state){
            return state.discountCodes.filter(x => x.disabled == false)
        },
        restaurantAddress(state){
            return state.restaurantAddress
        },
        hasCheckedDiscountCodes(state){
          return state.hasCheckedDiscountCodes
        },
        acceptingOrders(state){
          return state.acceptingOrders
        },
        hasCheckedInfo(state){
            return state.hasCheckedInfo
        },
        id(state){
            return state.id
        },
        zipCodesShort(state){
            return state.zipCodes.map(zipCode => zipCode.zipCode)
        },
        zipCodes(state){
          return state.zipCodes
        },
        sortedZipCodes(state){
          return [...state.zipCodes].sort((a, b) => parseFloat(a.zipCode) - parseFloat(b.zipCode));
        },
        getFullZipCode:(state) => (zipCode) => {
            return state.zipCodes.find(x=> x.zipCode==zipCode) || {zipCode:'', deliveryFee:''}
        }
    },
    mutations: {
        SET_ZIP_CODES(state, arr) {
            state.zipCodes = arr
        },
        SET_OPENING_HOURS(state, arr) {
            arr.sort((a, b) => parseInt(b.openingTime.substring(0,2)) - parseInt(a.openingTime.substring(0,2)))
            state.openingHours = arr
        },
        SET_RESTAURANT_ADDRESS(state, obj) {
            state.restaurantAddress = obj
        },
        SET_DISCOUNT_CODES(state,arr){
          state.discountCodes = arr
        },
        ADD_DISCOUNT_CODE(state,obj){
          state.discountCodes.push(obj)
        },
        DELETE_DISCOUNT_CODE(state, id) {
            let index = state.discountCodes.findIndex(code => code.id == id)
            state.discountCodes.splice(index, 1)
        },
        DISABLE_DISCOUNT_CODE(state,id){
            let index = state.discountCodes.findIndex(code => code.id == id)
            Vue.set(state.discountCodes[index],'disabled', true)

        },
        ENABLE_DISCOUNT_CODE(state,id){
            let index = state.discountCodes.findIndex(code => code.id == id)
            Vue.set(state.discountCodes[index],'disabled', false)
        },
        SET_ACCEPTING_ORDERS(state, payload) {
            state.acceptingOrders = payload
        },
        SET_PAYMENT_OPTIONS(state, payload) {
            state.paymentOptions = payload
        },
        SET_RESTAURANT_INFO(state, payload) {
            state.paymentOptions = payload.paymentOptions
            state.zipCodes = payload.zipCodes
            state.acceptingOrders = payload.acceptingOrders
            payload.openingHours.sort((a, b) => parseInt(a.openingTime.substring(0,2)) - parseInt(b.openingTime.substring(0,2)))
            state.openingHours = payload.openingHours
        },
        HAS_CHECKED_INFO(state) {
            state.hasCheckedInfo = true
        },
        HAS_CHECKED_DISCOUNT_CODES(state){
          state.hasCheckedDiscountCodes = true
        },
        SET_OUT_OF_DATE(state) {
            state.outOfDate = true
        }
    },
    actions: {
        fetchRestaurantInfo({state, commit}) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('restaurants').doc(state.id).get().then((snapshot) => {
                    const data = snapshot.data()
                    let obj = {
                        paymentOptions: data.paymentOptions,
                        zipCodes: data.zipCodes,
                        acceptingOrders: data.acceptingOrders,
                        openingHours: data.openingHours
                    }
                    if(localStorage.lastUpdated != data.lastUpdated.toString()) {
                        commit("SET_OUT_OF_DATE")
                        localStorage.lastUpdated = data.lastUpdated.toString();
                    }
                    commit('SET_RESTAURANT_INFO', obj)
                    commit("HAS_CHECKED_INFO");
                    resolve(true)
                }).catch(reject)

            })
        },
        fetchRestaurantAddress({state, commit}) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('restaurantAddress').doc(state.id).get().then((snapshot) => {
                    const data = snapshot.data()
                    commit('SET_RESTAURANT_ADDRESS', data)
                    resolve(true)
                }).catch(reject)
            })
        },
        toggleRestaurantAvailability({state, commit}) {
            if(state.acceptingOrders){
                firebase.firestore().collection('restaurants').doc(state.id).update({acceptingOrders:false}).then(()=>{
                    commit('SET_ACCEPTING_ORDERS', false);
                }).catch(err => {console.log(err)})
            } else {
                firebase.firestore().collection('restaurants').doc(state.id).update({acceptingOrders:true}).then(()=>{
                    commit('SET_ACCEPTING_ORDERS', true);
                }).catch(err => {console.log(err)})
            }
        },
        updateZipCodes({state, commit}, payload) {
            firebase.firestore().collection('restaurants').doc(state.id).update({zipCodes:payload}).then(()=>{
                commit('SET_ZIP_CODES', payload);
            }).catch(err => {console.log(err)})
        },
        updateOpeningHours({state, commit}, payload) {
            firebase.firestore().collection('restaurants').doc(state.id).update({openingHours:payload}).then(()=>{
                commit('SET_OPENING_HOURS', payload);
            }).catch(err => {console.log(err)})
        },
        fetchDiscountCodes({state, commit}) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(state.id)
                    .collection("discountCodes").get().then((querySnapshot) => {
                    let arr = querySnapshot.docs.map(function (documentSnapshot) {
                        let obj = documentSnapshot.data();
                        obj.id = documentSnapshot.id;
                        return obj
                    });
                    commit("SET_DISCOUNT_CODES", arr);
                    commit("HAS_CHECKED_DISCOUNT_CODES");
                    resolve()
                }).catch(err=>{
                    reject(err)
                });
            })
        },
        addDiscountCode({state,commit}, originalObj){
            return new Promise((resolve, reject) => {
                let obj = JSON.parse(JSON.stringify(originalObj))
                if (obj.id) {
                    delete obj.id
                }
                firebase.firestore().collection("restaurants").doc(state.id).collection("discountCodes").add(obj).then(docRef => {
                    obj.id = docRef.id
                    commit("ADD_DISCOUNT_CODE", obj);
                    resolve(obj)
                }).catch(function (error) {
                    console.error("Error adding DiscountCode: ", error);
                    reject()
                });
            })
        },
        deleteDiscountCode({state, commit}, id) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(state.id).collection("discountCodes").doc(id).delete().then(function () {
                    commit("DELETE_DISCOUNT_CODE", id);
                    resolve()
                }).catch(function (error) {
                    console.error("Error removing DiscountCode: ", error);
                    reject()
                });
            })
        },
        disableDiscountCode({state, commit}, id) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(state.id).collection("discountCodes").doc(id).update({disabled:true}).then(function () {
                    commit("DISABLE_DISCOUNT_CODE", id);
                    resolve()
                }).catch(function (error) {
                    console.error("Error disabling DiscountCode: ", error);
                    reject()
                });
            })
        },
        enableDiscountCode({state, commit}, id) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection("restaurants").doc(state.id).collection("discountCodes").doc(id).update({disabled:false}).then(function () {
                    commit("ENABLE_DISCOUNT_CODE", id);
                    resolve()
                }).catch(function (error) {
                    console.error("Error enabling DiscountCode: ", error);
                    reject()
                });
            })
        },
    }

}

<template>
<span v-if="hasCheckedInfo">
  <v-row class="pb-1" style="width: 80vw; max-width: 350px" no-gutters v-for="weekday in weekdays" :key="weekday.day" :class="{'font-weight-bold' : weekday.day == currentWeekday}">
    <v-col cols="6">
    {{weekday.name}}:
    </v-col>
    <v-col cols="6">
      <v-row no-gutters class="text-right">
        <v-col cols="12" class="text-no-wrap"  v-for="(openingHour) in openingHours.filter(x => x.day == weekday.day)" :key="openingHour.openingTime.toString() + openingHour.closingTime.toString()">
            {{openingHour.openingTime}}&nbsp;&#8209;&nbsp;{{openingHour.closingTime}}
        </v-col>
        <v-col cols="12" v-if="openingHours.filter(x => x.day == weekday.day).length == 0">
          Geschlossen
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</span>
  <v-layout align-center justify-center class="pt-5 pb-8" v-else>
    <v-progress-circular
        indeterminate
        size="80"
        color="primary"
    ></v-progress-circular>
  </v-layout>
</template>

<script>

import {mapGetters} from "vuex";

export default {
name: "OpeningHourDisplay",
  data() {
  return {
    weekdays: [
      {
        name: 'Montag',
        day: 1
      },
      {
        name: 'Dienstag',
        day: 2
      },
      {
        name: 'Mittwoch',
        day: 3
      },
      {
        name: 'Donnerstag',
        day: 4
      },
      {
        name: 'Freitag',
        day: 5
      },
      {
        name: 'Samstag',
        day: 6
      },
      {
        name: 'Sonntag',
        day: 0
      },
    ],
    currentWeekday: new Date().getDay()
  };
},
  computed: {
    ...mapGetters({
      openingHours: 'restaurantStore/openingHours',
      hasCheckedInfo: 'restaurantStore/hasCheckedInfo',
    }),
  }

}
</script>

<style scoped>

</style>
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';
import {vuetifyTheme} from "@/restaurantConfig";


Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        themes: {
            light: vuetifyTheme,
        },
        options: {
            customProperties: true
        },
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    icons: {
        iconfont: 'mdiSvg',
    },
});

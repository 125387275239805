import home from '@/restaurants/ni-hao-kaiser/home'
const restaurantName = "ni-hao-kaiser"
const firebaseTarget = "nihao"
const restaurantId = "4C8uIXIw41BvtRynR0gV"
const restaurantString = 'Ni Hao Kaiser'
const restaurantSubString = 'Eckenheimer Landstraße 453'
const showCouponSettings = true
const menuAccentColor = 'green'
const logoInAppBar = true

const vuetifyTheme = {
    primary: '#CB9173',
    secondary: '#a491d3',
    accent: '#818aa3',
    error: '#f9dad0',
    background: '#fff',
    cards: '#fff'
}

const appBarColor = 'rgba(13, 3, 29, .95)'
const appBarDarkMode = true



const extraCategoryPics = []

export {restaurantName, appBarDarkMode, home, firebaseTarget, appBarColor, restaurantId, restaurantString, showCouponSettings, restaurantSubString, menuAccentColor, vuetifyTheme, logoInAppBar, extraCategoryPics}
import Vue from "vue";
import Vuex from "vuex";
import {menuStore} from "./modules/menu";
import {restaurantStore} from "./modules/restaurant";
import {userStore} from "./modules/user"
import {shoppingCartStore} from "./modules/shoppingCart";
import {orderStore} from "./modules/order";

Vue.use(Vuex);
export const store = new Vuex.Store({
    namespaced: true,
    modules: {
        menuStore,
        restaurantStore,
        userStore,
        shoppingCartStore,
        orderStore
    },
    plugins: []
});


import Vue from 'vue'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import {store} from './store/index';
import LoadScript from 'vue-plugin-load-script'
import VueCurrencyFilter from 'vue-currency-filter';

Vue.use(VueCurrencyFilter,
    {
      symbol : '€',
      thousandsSeparator: '.',
      fractionCount: 2,
      fractionSeparator: ',',
      symbolPosition: 'back',
      symbolSpacing: true
    })
Vue.use(LoadScript)
Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBlYq4ULrT3SB-ME2xl7eMyR1n5bYWSr8Y",
  authDomain: "bestellen-d01fa.firebaseapp.com",
  databaseURL: "https://bestellen-d01fa.firebaseio.com",
  projectId: "bestellen-d01fa",
  storageBucket: "bestellen-d01fa.appspot.com",
  messagingSenderId: "1049047837172",
  appId: "1:1049047837172:web:9af573ec426954ec8871a1",
  measurementId: "G-EP04FNT3GB"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("userStore/fetchUser", user);
});

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');

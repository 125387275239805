<template>
    <v-app id="app">
        <app-bar></app-bar>
        <v-main :class="{'white pt-0' : ($route.name=='index' || !$route.name)}">
            <router-view></router-view>
        </v-main>
        <app-footer></app-footer>
        <cookie-law
                buttonText="Akzeptieren"
                v-if="!loading"
        :theme="$route.name=='index' ? 'dark-lime' : 'base'">
            <div slot="message">
                Wir nutzen Cookies, um unsere Dienste zu erbringen und zu verbessern. Mit Nutzung dieser Seite akzeptieren Sie Cookies. <router-link :to="{ name: 'privacy'}">Mehr.</router-link>
            </div>
        </cookie-law>
    </v-app>
</template>

<script>
    import AppBar from "./components/AppBar";
    import AppFooter from "./components/AppFooter";
    import {store} from './store/index';
    export default {
        name: 'App',
        components: {AppFooter, AppBar, CookieLaw: () => import('vue-cookie-law')},
        created() {
            store.dispatch('userStore/getZipCodeFromLocalStorage')
            store.dispatch('restaurantStore/fetchRestaurantInfo')
            this.loading = false
        },
        data() {
            return {
                loading: true
            }
        }
    };
</script>
<style>
    #app {
        background-color: var(--v-background-base);
    }
</style>

import Vue from "vue";
import firebase from "firebase/app";

export const orderStore = {
    namespaced: true,
    state: {
        singleOrder: undefined,
        orders: [],
        lastOrder: undefined,
        restaurantOrders: [],
        todayRestaurantListener:undefined,
        hasCheckedOrders: false,
        checkedDaysRestaurant: [],
    },
    getters: {
        orders(state) {
            return state.orders
        },
        todayRestaurantListener(state) {
            return state.todayRestaurantListener
        },
        singleOrder(state) {
            return state.singleOrder
        },
        lastOrder(state) {
            return state.lastOrder
        },
        checkedDaysRestaurant(state) {
            return state.checkedDaysRestaurant
        },
        sortedOrders: (state) => (date) => {
            if (date) {
                return state.orders.filter(order => order.timestamp.getFullYear() === date.getFullYear() &&
                    order.timestamp.getMonth() === date.getMonth() &&
                    order.timestamp.getDate() === date.getDate()).sort(function (a, b) {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                })
            } else {
                return state.orders.sort(function (a, b) {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                })
            }
        },
        sortedRestaurantOrders: (state) => (date) => {
            if (date) {
                return state.restaurantOrders.filter(order => order.timestamp.getFullYear() === date.getFullYear() &&
                    order.timestamp.getMonth() === date.getMonth() &&
                    order.timestamp.getDate() === date.getDate()).sort(function (a, b) {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                })
            } else {
                return state.restaurantOrders.sort(function (a, b) {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                })
            }
        },
        sortedRestaurantOrdersDateRange: (state) => (data) => {
            let startDate = new Date(data.start.setHours(0, 0, 0, 0))
            let endDate = new Date(data.end.setHours(0, 0, 0, 0))
            endDate.setDate(endDate.getDate() + 1)
            if (startDate && endDate) {
                return state.restaurantOrders.filter(order => {
                        return startDate.getTime() <= order.timestamp.getTime() && order.timestamp.getTime() <= endDate.getTime()
                    }
                ).sort(function (a, b) {
                    return new Date(a.timestamp) - new Date(b.timestamp) ;
                })
            } else {
                return state.restaurantOrders.sort(function (a, b) {
                    return new Date(a.timestamp) - new Date(b.timestamp);
                })
            }
        },
        hasCheckedOrders(state) {
            return state.hasCheckedOrders
        },
        findOrder: (state) => (id) => {
            return state.orders.find(order => order.id === id)
        }
    },
    mutations: {
        HAS_CHECKED_ORDERS(state) {
            state.hasCheckedOrders = true
        },
        SET_RESTAURANT_ORDER(state, payload) {
            let id = payload.id
            let  index = state.restaurantOrders.findIndex(order => order.id == id)
            Vue.set(state.restaurantOrders, index, payload)
        },
        ADD_ORDER(state, obj) {
            state.orders.push(obj)
        },
        ADD_ORDER_RESTAURANT(state, obj) {
            state.restaurantOrders.push(obj)
        },
        ADD_ORDERS(state, arr) {
            Vue.set(state, 'orders', state.orders.concat(arr))
        },
        ADD_RESTAURANT_ORDERS(state, arr) {
            Vue.set(state, 'restaurantOrders', state.restaurantOrders.concat(arr))
        },
        SET_LAST_ORDER(state, obj) {
            Vue.set(state, 'lastOrder', obj)
        },
        SET_RESTAURANT_ORDERS(state, obj) {
            Vue.set(state, 'restaurantOrders', obj)
        },
        SET_SINGLE_ORDER(state, obj) {
            Vue.set(state, 'singleOrder', obj)
        },
        SET_TODAYS_RESTAURANT_LISTENER(state, obj) {
            Vue.set(state, 'todayRestaurantListener', obj)
        },
        ADD_DATE_TO_CHECKED_RESTAURANT(state, obj) {
            state.checkedDaysRestaurant.push(obj)
        }
    },
    actions: {
        fetchPaginatedOrders({commit, rootState}) {
            return new Promise((resolve, reject) => {
                if (rootState.userStore.loggedIn === false || rootState.orderStore.lastOrder === null) {
                    reject()
                }
                let queryRef = firebase.firestore().collection('orders').where("restaurant", "==", rootState.restaurantStore.id).where("orderingUser", "==", rootState.userStore.user.data.uid)
                    .orderBy('timestamp', "desc").limit(10)
                if (rootState.orderStore.lastOrder) {
                    queryRef = queryRef.startAfter(rootState.orderStore.lastOrder)
                }
                queryRef.get()
                    .then((querySnapshot) => {
                        let arr = querySnapshot.docs.map(function (documentSnapshot) {
                            let obj = documentSnapshot.data();
                            obj.timestamp = obj.timestamp.toDate()
                            obj.id = documentSnapshot.id;
                            return obj
                        });
                        if (!querySnapshot.empty && querySnapshot.docs.length === 10) {
                            commit("SET_LAST_ORDER", querySnapshot.docs[querySnapshot.docs.length - 1]);
                        } else {
                            commit("SET_LAST_ORDER", null)
                        }
                        commit("HAS_CHECKED_ORDERS")
                        commit("ADD_ORDERS", arr);
                        resolve(true)
                    }).catch(reject)

            })
        },
        fetchRestaurantOrdersByDay({commit, rootState}, date) {
            return new Promise((resolve, reject) => {
                if (rootState.userStore.loggedIn === false) {
                    reject()
                }
                let startDate = new Date(date.setHours(0, 0, 0, 0))
                let endDate = new Date(date.setHours(0, 0, 0, 0))
                endDate.setDate(endDate.getDate() + 1)
                if (!rootState.orderStore.checkedDaysRestaurant.includes(startDate.toISOString())) {
                    firebase.firestore().collection('orders').where("restaurant", "==", rootState.restaurantStore.id).where('timestamp', '>', startDate).where('timestamp', '<', endDate).get()
                        .then((querySnapshot) => {
                            let dict = querySnapshot.docs.map(function (documentSnapshot) {
                                let obj = documentSnapshot.data();
                                obj.timestamp = obj.timestamp.toDate()
                                obj.id = documentSnapshot.id;
                                return obj
                            });
                            commit("ADD_DATE_TO_CHECKED_RESTAURANT", startDate.toISOString())
                            commit("ADD_RESTAURANT_ORDERS", dict);
                            resolve(true)
                        }).catch((err) => {
                        console.log(err)
                        reject()
                    })
                } else {
                    resolve('alreadyDone')
                }

            })
        },
        fetchRestaurantOrdersByDateRange({commit, rootState}, {start, end}) {
            return new Promise((resolve, reject) => {
                if (rootState.userStore.loggedIn === false) {
                    reject()
                }
                let startDate = new Date(start.setHours(0, 0, 0, 0))
                let endDate = new Date(end.setHours(0, 0, 0, 0))
                endDate.setDate(endDate.getDate() + 1)
                firebase.firestore().collection('orders').where("restaurant", "==", rootState.restaurantStore.id).where('timestamp', '>', startDate).where('timestamp', '<', endDate).get()
                    .then((querySnapshot) => {
                        let dict = querySnapshot.docs.map(function (documentSnapshot) {
                            let obj = documentSnapshot.data();
                            obj.timestamp = obj.timestamp.toDate()
                            obj.id = documentSnapshot.id;
                            return obj
                        });
                        let loop = new Date(startDate)
                        while(loop <= endDate) {
                            let nextLoop = new Date(new Date(loop).setDate(loop.getDate() + 1))
                            if (!rootState.orderStore.checkedDaysRestaurant.includes(loop.toISOString())) {
                                console.log(loop)
                                console.log(nextLoop)
                                console.log(dict.filter(x => x.timestamp < nextLoop && x.timestamp > loop))
                                console.log('---')
                                commit("ADD_DATE_TO_CHECKED_RESTAURANT", loop.toISOString())
                                commit("ADD_RESTAURANT_ORDERS", dict.filter(x => x.timestamp < nextLoop && x.timestamp > loop));
                            }
                            loop = nextLoop
                        }
                        resolve(true)
                    }).catch((err) => {
                    console.log(err)
                    reject()
                })
            })
        },
        attachTodaysRestaurantOrders({commit, rootState}) {
            if (rootState.userStore.loggedIn === false) {
                return null
            }
            let startDate = new Date(new Date().setHours(0, 0, 0, 0))
            let endDate = new Date(new Date().setHours(0, 0, 0, 0))
            endDate.setDate(endDate.getDate() + 1)
            if (!rootState.orderStore.checkedDaysRestaurant.includes(startDate.toISOString())) {
                commit("ADD_DATE_TO_CHECKED_RESTAURANT", startDate.toISOString())
                let listener =  firebase.firestore().collection('orders').where("restaurant", "==", rootState.restaurantStore.id).where('timestamp', '>', startDate).where('timestamp', '<', endDate)
                    .onSnapshot((querySnapshot) => {
                        querySnapshot.docChanges().forEach(function (change) {
                            if (change.type === "added") {
                                let obj = change.doc.data();
                                obj.timestamp = obj.timestamp.toDate()
                                obj.id = change.doc.id;
                                commit("ADD_ORDER_RESTAURANT", obj)
                            }
                            if (change.type === "modified") {
                                let obj = change.doc.data();
                                obj.timestamp = obj.timestamp.toDate()
                                obj.id = change.doc.id;
                                commit("SET_RESTAURANT_ORDER", obj)
                            }
                            if (change.type === "removed") {
                                console.log("REMOVED ORDER: ", change.doc.data());
                            }
                        });
                    })
                commit("SET_TODAYS_RESTAURANT_LISTENER", listener)

            }
        },
        fetchSingleOrder({commit, rootState}, id) {
            return new Promise((resolve, reject) => {
                if (rootState.userStore.loggedIn === false) {
                    reject()
                }
                firebase.firestore().collection('orders').doc(id).get()
                    .then((docSnapshot) => {
                        let obj = docSnapshot.data()
                        obj.id = docSnapshot.id
                        obj.timestamp = obj.timestamp.toDate()
                        commit("SET_SINGLE_ORDER", obj);
                        resolve(true)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        acceptOrder({rootState}, {id,estimatedDeliveryTime}) {
            return new Promise((resolve, reject) => {
                if (rootState.userStore.loggedIn === false) {
                    reject()
                }
                firebase.firestore().collection('orders').doc(id).update({accepted:true, estimatedDeliveryTime:estimatedDeliveryTime}).then(()=>{resolve()})
                    .catch(err => {
                    reject(err)
                })

            })
        }
    }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
const index = () => import("../views/index")
const menu = () => import("../views/menu")
const login = () => import('../views/login')
const impressum = () => import("../views/impressum")
const contact = () => import("../views/contact")
const profile = () => import("../views/profile")
const manage_bookings = () => import("../views/manage/bookings")
const manage_settings = () => import("../views/manage/settings")
const privacy = () => import("../views/privacy")
const tac = () => import("../views/tac")
const checkout = () => import("../views/checkout")
const order_ID = () => import("../views/order/ID/index")
const order_ID_print = () => import("../views/order/ID/print")


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/menu',
    name: 'menu',
    component: menu
  },
  {
    path: '/register',
    name: 'register',
    component: login,
    props: {
      register: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
/*    beforeEnter: (to, from, next) => {
      if (userStore.state.user.loggedIn){
        next(false)
      } else next()
    }*/
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: impressum,
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
  },
  {
    path: '/manage/bookings',
    name: 'manage-bookings',
    component: manage_bookings,
  },
  {
    path: '/manage/settings',
    name: 'manage-settings',
    component: manage_settings,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/tac',
    name: 'tac',
    component: tac
  },

  {
    path: '/checkout',
    name: 'checkout',
    component: checkout,
/*    beforeEnter: (to, from, next) => {
      if (!userStore.state.user.loggedIn){
        next({name:"Login"})
      } else next()
    }*/
  },
  {
    path: '/order/:id',
    name: 'order-ID',
    component: order_ID,
  },
  {
    path: '/order/:id/print',
    name: 'order-ID-print',
    component: order_ID_print
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router

import firebase from 'firebase/app';
import Vue from "vue";

export const userStore = {
    namespaced: true,
    state: {
        user: {
            hasCheckedLogin: false,
            loggedIn: false,
            data: null,
            zipCode: undefined,
            addresses: [],
            claims: {}
        }
    },
    getters: {
        user(state){
            return state.user
        },
        phoneNumber(state){
            if (state.user.data){
                return state.user.data.phoneNumber
            } else {
                return undefined
            }
        },
        minimumOrder(state){
            let zipCode = state.user.zipCode
            if(zipCode){
                return zipCode.minimumOrder || 0
            } else {return 0}
        },
        email(state){
            if (state.user.data){
                return state.user.data.email
            } else {
                return undefined
            }
        },
        loggedIn(state){
            return state.user.loggedIn
        },
        zipCode(state){
            return state.user.zipCode
        },
        addresses(state){
            return state.user.addresses
        },
        addressesInRestaurantZipCodes(state, getters, rootState){
            return state.user.addresses.filter(address => rootState.restaurantStore.zipCodes.map(zipCode => zipCode.zipCode).includes(address.zipCode)
            )
        },
        hasCheckedLogin(state){
            return state.user.hasCheckedLogin
        },
        claims(state){
            return state.user.claims
        },
        avatarUrl(state){
            if(state.user.data){
                return state.user.data.avatar
            } else {
                return undefined
            }
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            Vue.set(state.user,'data',data)
        },
        SET_USER_ATTRIBUTE(state, {key,value}) {
            state.user.data[key] = value;
        },
        SET_ZIP_CODE(state, value){
            state.user.zipCode = value;
        },
        SET_CLAIMS(state, value){
            state.user.claims = value;
        },
        SET_ADDRESSES(state, value){
            state.user.addresses = value;
        },
        DELETE_ADDRESS(state, id){
            let index = state.user.addresses.findIndex(x => x.id == id);
            state.user.addresses.splice(index, 1)
        },
        ADD_ADDRESS(state, obj){
            state.user.addresses.push(obj)
        },
        EDIT_ADDRESS(state, obj){
            let index = state.user.addresses.findIndex(x => x.id == obj.id);
            Vue.set(state.user.addresses, index, obj)
        },
        HAS_CHECKED_LOGIN(state){
            state.user.hasCheckedLogin = true
        }
    },
    actions: {
        setZipCode({commit}, value) {
            commit("SET_ZIP_CODE", value);
            if(value) {
                localStorage.zipCode = JSON.stringify(value)
                commit("shoppingCartStore/SET_DELIVERY_FEE", value.deliveryFee, {root:true});
            } else {
                localStorage.removeItem('zipCode')
                commit("shoppingCartStore/SET_DELIVERY_FEE", 0, {root:true});
            }

        },
        getZipCodeFromLocalStorage({commit}) {
            if (localStorage.zipCode){
                commit("SET_ZIP_CODE", JSON.parse(localStorage.zipCode));
                commit("shoppingCartStore/SET_DELIVERY_FEE", JSON.parse(localStorage.zipCode).deliveryFee, {root:true});
            }
        },
        fetchUser({ commit, rootState }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                firebase.firestore().collection("users").doc(user.uid).collection("addresses").get().then((querySnapshot) => {
                    //This gets the user's addresses from firebase and stores them
                    let arr = querySnapshot.docs.map(function (documentSnapshot) {
                        let obj = documentSnapshot.data();
                        obj.id = documentSnapshot.id;
                        return obj
                    });
                    commit("SET_ADDRESSES", arr);
                    commit("HAS_CHECKED_LOGIN");
                });
                user.getIdTokenResult()
                    .then((idTokenResult) => {
                        commit("SET_CLAIMS", idTokenResult.claims);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                commit("SET_USER", {
                    email: user.email,
                    avatar: user.photoURL,
                    uid: user.uid,
                    phoneNumber: user.phoneNumber,
                });
            } else {
                commit("SET_USER", null);
                commit("SET_ADDRESSES", []);
                commit("SET_CLAIMS", {})
                commit("SET_ZIP_CODE", undefined);
                commit("HAS_CHECKED_LOGIN");
                rootState.orderStore.orders = []
                rootState.orderStore.restaurantOrders = []
            }
        },
    },
}

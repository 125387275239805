import {uuidv4} from "../../utils/uuid"
import Vue from 'vue'

export const shoppingCartStore = {
    namespaced: true,
    state: {
        items: [],
        deliveryFee: undefined,
        hasCheckedCart: false,
    },
    getters: {
        items(state) {
            return state.items
        },
        deliveryFee(state){
          return state.deliveryFee
        },
        hasCheckedCart(state){
            return state.hasCheckedCart
        },
        numberOfItemsInCart(state){
            return state.items.length
        },
        totalPrice(state, getters){
            let itemPrice = getters.totalPriceWithoutDeliveryFee
            if (state.deliveryFee){
                itemPrice = Number(itemPrice) + Number(state.deliveryFee)
            }
            return itemPrice
        },
        totalPriceWithoutDeliveryFee(state){
            let itemPrice = 0
            itemPrice =  state.items.reduce((total, item) =>
                total + Number(item.count) * (Number(item.price) + item.extras.reduce((price, extra) => Number(price) + Number(extra.price), 0) + Object.values(item.questions).reduce((price, question) => {
                    let number = Number(price) + Number(question.answer.price)
                    if(question.answer['chosenExtras']) {
                        number += question.answer['chosenExtras'].reduce((price, extra) => Number(price) + Number(extra.price), 0)
                    }
                    return number
                }, 0))
                , 0)
            return itemPrice
        }
    },
    mutations: {
        SET_ITEMS(state,payload){
            state.items = payload
        },
        clearCart(state) {
            state.items = []
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        SET_DELIVERY_FEE(state, payload) {
            state.deliveryFee = payload
        },
        updateItem(state, payload) {
            let index = state.items.findIndex(order => order.id == payload.item.id)
            let item = payload.item
            item.count = payload.count
            Vue.set(state.items, index, item)
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        addItem(state, payload) {
            let item = payload.item;
            let count = payload.count;
            if(count){
                item.count = count;
            } else {
                item.count = 1;
            }
            item.id = uuidv4();
            state.items.push(item)
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        deleteItem(state, id){
            let index = state.items.findIndex(item => item.id === id);
            state.items.splice(index, 1);
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        incrementItemByCount(state, payload){
            let count = payload.count;
            let id = payload.id;
            let index = state.items.findIndex(item => item.id === id);
            Vue.set(state.items[index], 'count', state.items[index].count + count)
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        incrementItem(state, id){
            let index = state.items.findIndex(item => item.id === id);
            Vue.set(state.items[index], 'count', state.items[index].count ++)
            localStorage.shoppingCart = JSON.stringify(state.items);
        },
        decrementItem(state, id){
            let index = state.items.findIndex(item => item.id === id);
            if(state.items[index].count >1){
                Vue.set(state.items[index], 'count', state.items[index].count --)
                localStorage.shoppingCart = JSON.stringify(state.items);
            } else {
                state.items.splice(index, 1);
                localStorage.shoppingCart = JSON.stringify(state.items);
            }
        },
        setItemCount(state, {id, count}) {
            let index = state.items.findIndex(item => item.id === id);
            if(count >=1){
                state.items[index].count = count
                localStorage.shoppingCart = JSON.stringify(state.items);
            } else {
                state.items.splice(index, 1);
                localStorage.shoppingCart = JSON.stringify(state.items);
            }
        }
    },
    actions: {
        getCartFromLocalStorage({commit, rootState}) {
            if (localStorage.shoppingCart && !rootState.restaurantStore.outOfDate){
                commit('SET_ITEMS', JSON.parse(localStorage.shoppingCart));
            } else {
                localStorage.removeItem('shoppingCart');
            }
        },
        incrementOrAddItem({commit}, payload) {
            // payload should be an object with an item and a count
            let item = payload.item;
            let count = payload.count;
                commit('addItem', {count: count, item: item});
        }
    }
}
